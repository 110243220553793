import React, { useState } from 'react'
import { ArrowUpDownIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from '@chakra-ui/react'
import * as R from 'ramda'
import { getNavigationProp } from 'lib/utils/withNavigationProps'
import useDoc from 'lib/api/hooks/useDoc'
import { getPausePath } from 'domains/pauses/models/IPause'
import { RouteComponentProps } from 'react-router'
import FormInput from 'lib/components/FormInput'
import Dialog from 'lib/components/Dialog'
import Paper from 'lib/components/Paper'
import FormSelect from 'lib/components/FormSelect'
import IPause, {
  getPauseTranscriptsPath,
  IPauseTranscript,
} from '@/pauses/models/IPause'
import { UploadPauseAsset } from '../components/UploadAsset'
import ColorPickerModal from 'lib/components/ColorPickerModal'
import useModalState from 'lib/api/hooks/useModalState'
import AudioDuration from '~/components/AudioDuration'
import { milliSecondsToFriendlyTime } from 'lib/utils/time'
import useCollection from 'lib/api/hooks/useCollection'
import { orderBy } from 'firebase/firestore'
import {
  convertTranscriptsToSrtFileFormat,
  convertTranscriptsToTxtFileFormat,
} from 'lib/utils/convert'
import useDuplicatePause from '../hooks/useDuplicatePause'
import ModalWrapper from 'lib/components/ModalWrapper'
import { deletePause } from '../workflow/deletePause'

const optionsTen = R.repeat(null, 11).map((_: any, i: number) => ({
  value: i,
  label: `${i}`,
}))
const optionsTenOneDecimal = R.repeat(null, 101).map((_: any, i: number) => ({
  value: (i / 100) * 10,
  label: `${((i / 100) * 10).toFixed(1)} Seconds`,
}))
const optionsTenHalves = R.repeat(null, 21).map((_: any, i: number) => ({
  value: (i / 20) * 10,
  label: `${((i / 20) * 10).toFixed(1)} Seconds`,
}))

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'That is a pretty short title...')
    .matches(
      /^[a-zA-Z0-9 •+\-()/]*$/,
      'Title must only contain letters, numbers, spaces, or •+-()/',
    )
    .required('Required'),
  published: Yup.boolean().required('Required'),
  showOnHomeScreen: Yup.boolean().required('Required'),
  homeScreenOrder: Yup.number()
    .min(0, 'Home screen order must be greater than or equal to 0')
    .max(99, 'Home screen order must be less than or equal to 99')
    .integer('Home screen order must be a whole number'),
})

export default function V2PauseDetailsScreen(props: RouteComponentProps) {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const pauseId = getNavigationProp(props, 'id') as string
  const pausePath = getPausePath(pauseId, true)
  const [pause, , updatePause] = useDoc<IPause>(pausePath)
  const transcriptsPath = getPauseTranscriptsPath(pauseId)
  const [transcripts] = useCollection<IPauseTranscript[]>(transcriptsPath, [
    orderBy('start', 'asc'),
  ])
  const [isColorPickerOpen, , , toggleColorPicker] = useModalState()
  const [isDuplicatingPause, duplicatePause] = useDuplicatePause(pauseId)

  if (!pause) {
    return <Spinner />
  }

  const onSave = async (values: IPause, bag: FormikHelpers<IPause>) => {
    const updated = {
      ...values,
      transcripts:
        values.transcripts?.sort(
          (a, b) => parseInt(`${a.start}`) - parseInt(`${b.start}`),
        ) || [],
    }
    await updatePause(updated)
    toast.success('Pause details saved!')
    bag.resetForm()
  }

  const onDuplicate = async () => {
    const newPauseId = await duplicatePause()
    props.history.push(`/v2pauses/${newPauseId}`)
  }

  const onDelete = () => setDeleteOpen(true)
  const onCloseDelete = () => setDeleteOpen(false)
  const onDeleteConfirmed = async () => {
    await deletePause(pauseId)
    setDeleteOpen(false)
    props.history.goBack()
  }

  // export functions
  const onExportToSrt = () => {
    const srtFileData = convertTranscriptsToSrtFileFormat(transcripts)
    downloadTextFile(`${pause.title}.srt`, srtFileData)
  }
  const onExportToTxt = () => {
    const txtFileData = convertTranscriptsToTxtFileFormat(transcripts)
    downloadTextFile(`${pause.title}.txt`, txtFileData)
  }
  /** Create a file of the given name and initiate browser download! */
  const downloadTextFile = (filename: string, data: string) => {
    const blob = new Blob([data], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = filename
    link.href = url
    link.click()
    link.remove()
  }

  return (
    <Formik
      initialValues={{
        ...{ published: false, showOnHomeScreen: false, homeScreenOrder: 0 }, // default values
        ...pause,
      }}
      onSubmit={onSave}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {formikProps => {
        const breathingLength =
          (formikProps.values.breathing?.startingDuration || 3.5) +
          (formikProps.values.breathing?.iterations || 0) *
            ((formikProps.values.breathing?.inLength || 0) +
              (formikProps.values.breathing?.holdInLength || 0) +
              (formikProps.values.breathing?.outLength || 0) +
              (formikProps.values.breathing?.holdOutLength || 0))
        const onBreathingLengthChanged = (name: string) => (
          e: React.ChangeEvent<HTMLSelectElement>,
        ) => formikProps.setFieldValue(name, parseFloat(e.target.value))
        const isDurationSet =
          !!formikProps.values.duration && formikProps.values.duration > 0

        return (
          <div>
            <Form className="space-y-6">
              <Paper
                title="Edit Pause"
                stickyTop
                actions={
                  <div className="flex flex-row align-center space-x-2">
                    <Button
                      colorScheme="green"
                      type="submit"
                      isLoading={formikProps.isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="green"
                      type="button"
                      onClick={onDuplicate}
                      isLoading={formikProps.isSubmitting}
                    >
                      Duplicate
                    </Button>
                    <Button
                      colorScheme="red"
                      type="button"
                      onClick={onDelete}
                      isLoading={formikProps.isSubmitting}
                    >
                      Delete
                    </Button>
                  </div>
                }
              >
                <div />
              </Paper>

              <Paper title="Pause Details">
                <div className="space-y-6 divide-y">
                  <Flex direction="row" align="center" my={3}>
                    <div className="flex flex-col flex-1">
                      <FormInput
                        label="Title"
                        name="title"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.title}
                      />
                      <div className="flex flex-col gap-4">
                        <Checkbox
                          isChecked={formikProps.values.published}
                          onChange={formikProps.handleChange}
                          name="published"
                        >
                          Published
                        </Checkbox>
                        {formikProps.values.published && (
                          <Checkbox
                            isChecked={formikProps.values.showOnHomeScreen}
                            onChange={formikProps.handleChange}
                            name="showOnHomeScreen"
                          >
                            Show on Home Screen
                          </Checkbox>
                        )}
                        {formikProps.values.published &&
                          formikProps.values.showOnHomeScreen && (
                            <FormInput
                              label="Home screen order (lower numbers appear first)"
                              name="homeScreenOrder"
                              placeholder="0-99"
                              onChange={formikProps.handleChange}
                              type="number"
                              value={
                                (formikProps.values.homeScreenOrder || 0) >= 0
                                  ? formikProps.values.homeScreenOrder
                                  : ''
                              }
                            />
                          )}
                      </div>
                    </div>
                  </Flex>

                  <div className="pt-4 flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                    <UploadPauseAsset
                      pauseAssetId={pauseId}
                      type="IMAGE"
                      label="Background Image"
                      url={formikProps.values.backgroundImageAsset}
                      onUrlChanged={url =>
                        formikProps.setFieldValue('backgroundImageAsset', url)
                      }
                    />

                    <UploadPauseAsset
                      pauseAssetId={pauseId}
                      type="MUSIC"
                      label="Music + Voice Audio"
                      url={formikProps.values.voiceAsset}
                      onUrlChanged={url => {
                        formikProps.setFieldValue('voiceAsset', url)
                        // clear duration if no URL
                        if (!url || url === '') {
                          formikProps.setFieldValue('duration', 0)
                        }
                      }}
                    />

                    <UploadPauseAsset
                      pauseAssetId={pauseId}
                      type="MUSIC"
                      label="Voice Only Audio"
                      url={formikProps.values.voiceOnlyAsset}
                      onUrlChanged={url =>
                        formikProps.setFieldValue('voiceOnlyAsset', url)
                      }
                    />

                    <UploadPauseAsset
                      pauseAssetId={pauseId}
                      type="MUSIC"
                      label="Music Only Audio"
                      url={formikProps.values.musicOnlyAsset}
                      onUrlChanged={url =>
                        formikProps.setFieldValue('musicOnlyAsset', url)
                      }
                    />
                  </div>

                  <div className="pt-4 flex flex-col gap-2">
                    <Text>
                      Total Duration:{' '}
                      {isDurationSet
                        ? milliSecondsToFriendlyTime(
                            formikProps.values.duration || 0,
                          )
                        : 'Not set'}
                    </Text>
                    {!isDurationSet && (
                      <Text fontStyle="italic">
                        Set music + voice audio to calculate
                      </Text>
                    )}
                  </div>
                </div>
              </Paper>

              <Paper title={`Breathing (${breathingLength.toLocaleString()}s)`}>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div
                      className="shadow-md p-4"
                      style={{ width: 'fit-content' }}
                    >
                      <FormLabel>Bubble Color</FormLabel>
                      <div
                        className="flex flex-row space-x-4 cursor-pointer"
                        onClick={toggleColorPicker}
                      >
                        <div className="flex flex-col items-center space-y-2 p-4 relative">
                          <div
                            className="w-16 h-16"
                            style={{
                              backgroundColor:
                                formikProps.values.breathing?.backgroundColor,
                            }}
                          />
                          <p className="text-gray-700 italic">
                            {formikProps.values.breathing?.backgroundColor ||
                              ''}
                          </p>
                          <div className="absolute top-2 right-2">
                            <IconButton
                              icon={<ArrowUpDownIcon />}
                              aria-label="toggle color picker"
                              size="sm"
                            />
                          </div>
                        </div>
                      </div>
                      <ColorPickerModal
                        color={
                          formikProps.values.breathing?.backgroundColor ||
                          'rgba(0,0,0,1)'
                        }
                        open={isColorPickerOpen}
                        onChange={rgba =>
                          formikProps.setFieldValue(
                            'breathing.backgroundColor',
                            rgba,
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <FormSelect
                      label="Iterations"
                      name="breathing.iterations"
                      value={formikProps.values.breathing?.iterations}
                      onChange={formikProps.handleChange}
                      options={optionsTen}
                    />

                    <div className="md:grid md:grid-cols-2 gap-4">
                      <FormInput
                        label="Starting Message (Optional)"
                        name="breathing.startingMessage"
                        placeholder="Let's start with some breathing"
                        onChange={formikProps.handleChange}
                        value={
                          formikProps.values.breathing?.startingMessage || ''
                        }
                      />
                      <FormSelect
                        label="Start Message Duration"
                        name="breathing.startingDuration"
                        value={formikProps.values.breathing?.startingDuration}
                        onChange={onBreathingLengthChanged(
                          'breathing.startingDuration',
                        )}
                        options={optionsTenOneDecimal}
                      />
                    </div>

                    <div className="md:grid md:grid-cols-3 gap-4">
                      <FormInput
                        label="Breathe In Message"
                        name="breathing.inMessage"
                        placeholder="Breath in"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.breathing?.inMessage || ''}
                      />
                      <FormSelect
                        label="Breathe In Length"
                        name="breathing.inLength"
                        value={formikProps.values.breathing?.inLength}
                        onChange={onBreathingLengthChanged(
                          'breathing.inLength',
                        )}
                        options={optionsTenHalves}
                      />
                      <FormSelect
                        label="Hold In Length"
                        name="breathing.holdInLength"
                        value={formikProps.values.breathing?.holdInLength}
                        onChange={onBreathingLengthChanged(
                          'breathing.holdInLength',
                        )}
                        options={optionsTenHalves}
                      />

                      <FormInput
                        label="Breathe Out Message"
                        name="breathing.outMessage"
                        placeholder="Breath out"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.breathing?.outMessage || ''}
                      />
                      <FormSelect
                        label="Breathe Out Length"
                        name="breathing.outLength"
                        value={formikProps.values.breathing?.outLength}
                        onChange={onBreathingLengthChanged(
                          'breathing.outLength',
                        )}
                        options={optionsTenHalves}
                      />
                      <FormSelect
                        label="Hold Out Length"
                        name="breathing.holdOutLength"
                        value={formikProps.values.breathing?.holdOutLength}
                        onChange={onBreathingLengthChanged(
                          'breathing.holdOutLength',
                        )}
                        options={optionsTenHalves}
                      />
                    </div>
                  </div>
                </div>
              </Paper>

              <Paper title="Transcripts">
                <Box className="flex flex-row space-x-2">
                  <Button
                    colorScheme="green"
                    type="button"
                    onClick={() =>
                      props.history.push(`/v2Pauses/${pauseId}/transcripts`)
                    }
                  >
                    Visually Edit
                  </Button>
                  <Box>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        colorScheme="green"
                        disabled={transcripts.length <= 0}
                      >
                        Export
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={onExportToSrt}>
                          Download .srt transcript file
                        </MenuItem>
                        <MenuItem onClick={onExportToTxt}>
                          Download .txt transcript file
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
              </Paper>
            </Form>

            <Dialog
              isOpen={deleteOpen}
              message="Are you sure you want to delete this pause?"
              onConfirm={onDeleteConfirmed}
              onDeny={onCloseDelete}
              icon="delete"
              doubleConfirm={true}
            />

            <ModalWrapper
              isOpen={isDuplicatingPause}
              title={'Duplicating Pause...'}
              actions={[]}
            >
              <Box textAlign="center">
                <Spinner />
              </Box>
            </ModalWrapper>

            <AudioDuration
              url={formikProps.values.voiceAsset}
              onDurationChanged={duration => {
                formikProps.setFieldValue('duration', duration)
              }}
            />
          </div>
        )
      }}
    </Formik>
  )
}
